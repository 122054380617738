import { useState, useEffect } from "react";
import Axios from "axios";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import ViewTemplateTable from "../../component/viewTempComp/viewTempComp";
import { useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default function ViewTemplate() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState();
    const [templateName, setTemplateName] = useState();
    const [templateFixedName, setTemplateFixedName] = useState();
    const [templateId, setTemplateId] = useState(useParams().id);

    const [open, setOpen] = React.useState(false);

    const [newDocName, setNewDocName] = React.useState('');
    const [newDocDescription, setNewDocDescription] = React.useState('');

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    const [openName, setOpenName] = React.useState(false);

    const handleClickOpenName = () => {
      setOpenName(true);
    };
  
    const handleCloseName = () => {
      setOpenName(false);
    };


    const addDocument = async (e) => {
         
         try {
            await Axios({
                method: 'POST',
                withCredentials: true,
                data: {
                    template_id: templateId,
                    document_name: newDocName,
                    document_description: newDocDescription,
                },
                url: `${backEndUrl}/updateClient/addDocToTemplate`,
            });
            
            window.location.reload();
        } catch (error) {
            
    
         }
    }

    const changeTemplateName = async (e) => {
      //  e.preventDefault();
       
     try {
        await Axios({
            method: 'PUT',
            withCredentials: true,
            data: {
                template_id: templateId,
                template_name: templateName,
            },
            url: `${backEndUrl}/updateClient/updateTemplateName`,
        });
        
        window.location.reload();
    } catch (error) {
        

     }
    }

    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

 

     // loop through the data to get the template name

    useEffect(() => {
  if (data !== undefined) {
    for (let i = 0; i < data.docTemplates.length; i++) {
      if (data.docTemplates[i]._id === templateId) {
        setTemplateFixedName(data.docTemplates[i].name);
        break; // Exit loop once template name is found
      }
    }
  }
}, [data, templateId]);

     if (data === undefined) {

        return (<>Still loading...</>)
    }
    
    else {

    return (
        <div>
             <div className="home">
        <Sidebar
           storageUsed={data.storageUsed}
        />
  

    <div className="homeContainer">
    <Navebar
        userID={data.id}
            email={data.email}
    />


    <h2 style={{marginLeft:'3%', marginTop:'3%'}}>{templateFixedName}</h2>
    <form onSubmit={changeTemplateName}> 
    <div style={{marginLeft:'1%', marginTop:'3%'}} >
<Button variant='outlined'  onClick={handleClickOpen}>Modifier le nom du template</Button>
<Button style={{marginLeft:'3%'}} variant='outlined' onClick={handleClickOpenName}>Ajouter un document</Button>
    </div>
    </form>

   <ViewTemplateTable
              clients={data.docTemplates}
            //  rid={data.id}
             // getRowId={(row) => row.data.docTemplates._id}
           
        />  
        </div>
        
        </div>

        <React.Fragment>
   
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}
      >
        <DialogTitle>Changer le nom de votre Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez inscrire le nouveau nom de votre template.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Nouveau nom du template"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={changeTemplateName}>Valider</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>

    <React.Fragment>

      <Dialog
        open={openName}
        onClose={handleCloseName}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}
      >
        <DialogTitle>Ajouter un document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez définir le nom du document ainsi que sa description.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="Nom"
            label="Nom du document"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => setNewDocName(e.target.value)}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="description"
            name="description"
            label="Description du document"
            type="text"
            fullWidth
            variant="standard"
            multiline
            onChange={(e) => setNewDocDescription(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseName}>Annuler</Button>
          <Button variant='contained' onClick={addDocument}>Envoyer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
      
        </div>
    )
}
}

