import React from 'react';

const HeroSection = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ flex: 1 }}>
        <h1>Welcome to Our Website</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
      </div>
      <div style={{ flex: 1 }}>
        <img src="https://clipart-library.com/new_gallery/23-231724_butterfly-png-images-transparent-background-butterfly-png.png" alt="Description of the image" style={{ width: '100%', height: 'auto' }} />
      </div>
    </div>
  );
};

export default HeroSection;
