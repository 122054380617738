import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import Axios from "axios";
// import { userColumns, userRows } from "../../datatablesource";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import MUIDataTable from "mui-datatables";
import { GridToolbarFilterButton} from "@mui/x-data-grid";
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// import { use } from "../../../../api/routes/updateClient";

import { styled } from '@mui/material/styles';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


const ViewTemplateTable = (props) => {
 
  const [open, setOpen] = React.useState(false);
  const [docInfo, setDocInfo] = React.useState('');

  const [nameState, setNameState] = useState(false);
  const [docName, setDocName] = useState('');

  const [docDescription, setDocDescription] = useState('');
  const [descriptionState, setDescriptionState] = useState(false);



var templateID = useParams();


  const sendForm = async () => {
   


    try {
      await Axios({
        method: 'PUT',
        withCredentials: true,
        data: {
          name: docName,
          description: docDescription,
          template_id: templateID.id,
          doc_id: docInfo._id,
        },
        url: `${backEndUrl}/updateClient/updateDocInTemplate`,
      });
      
     window.location.reload();

    } catch (error) {
      
    }
    
  };


  const changeDocName = (event)=> {
  
    event.preventDefault();
    setNameState(true);
    setDocName(event.target.value);
  };


  const changeDocDescription = (event) => {
    event.preventDefault();
    setDescriptionState(true);
    setDocDescription(event.target.value);
  };

  const handleClickOpen = (docInfo) => {
    setDocName(docInfo.name);
    setDocDescription(docInfo.description);
  
    setDocInfo(docInfo);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDescriptionState(false)
    setNameState(false);
  };

    var a = useParams();
   
    for (let i = 0; i < props.clients.length; i++) {
      if (props.clients[i]._id === a.id) {
          // when we find the client, we need to store the indice of the client in the array
   
          // which then become the clientIndice i that we can use to render HTML
          var clientIndice = i;
   
      }
  }

    const [data, setData] = useState();

    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

     const handleDelete = async (id) => {
  
      try {
        await Axios({
          method: 'DELETE',
          withCredentials: true,
          data: {
             template_id: a.id,
              doc_id: id,
            
          },
          url: `${backEndUrl}/updateClient/deleteDocInTemplate`,
        });
        
       window.location.reload();
      } catch (error) {
        
      }  
      
    };  




  const columns2 = [
  
    { field: "client_name", headerName: "Nom ", width: 300 ,
    valueGetter: (params) =>
        `${params.row.name || ""} `,
    },
    

 
   
  ]



  // actionColumn is used here to add the action buttons
  const actionColumn = [

    { field: "de", headerName: "Description", width: 300 ,
    renderCell: (params) => {
      return (
        <div className="cellAction">
        <div>
<HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit" fontSize={18} >Description</Typography>
            <div style={{ maxWidth: 300 }}>
            <pre style={{fontSize: 15, fontFamily: 'Helvetica', whiteSpace: 'pre-wrap'}}>
            {params.row.description}
            </pre>
           </div>
          </React.Fragment>
        }
      >
              <InfoRoundedIcon style={{ color: 'orange' }}/>
      </HtmlTooltip>
    </div>
    </div>
      );
    }
  },



    {
      field: "Modifier",
      headerName: "Modifier",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">

             <Button
             variant="outlined"
             size="small"
             color="primary"

              
              onClick={() => handleClickOpen(params.row)}
              
            >Modifier</Button>


          </div>
          
        );
      },
    },


    {
      field: "Supprimer",
      headerName: "Supprimer",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">

             <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => handleDelete(params.row._id)}
              
            >Supprimer</Button>

            
          </div>
        );
      },
    },
    {
      field: "aperçu",
      headerName: "Fichier joint",
      width: 100,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center", marginLeft:'4%' }}>
          { params.row.doc_upload_consultantSide !== 'none' ?
          <VisibilityIcon  variant='outlined' size='small' color="success" onClick={async () => {
try {
  const response = await fetch(`${backEndUrl}/updateClient/viewDoc`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // credentials very important here, what makes req.authenticated work in the backend
    credentials: 'include',
    body: JSON.stringify({ key: params.row.doc_upload_consultantSide}), // Replace 'your_key_here' with the appropriate key
  });
  const data = await response.json();

  const newWindow = window.open();
  newWindow.location.href = data;
  // Do something with the pre-signed URL
} catch (error) {
  console.error('Error fetching pre-signed URL: ', error);
}
}}
style={{ marginLeft: "30%" , cursor:'pointer'}}/> : <p  style={{ marginLeft: "10%" }} >S.O</p>

        }
        </div>
        );
      },
    }
   
  ];

  if (data === undefined) {

    return (<>Still loading...</>)
}

else {


  return (
    
    <div className="datatable">
      <div className="datatableTitle">
      
      
      </div>
     
     <div>
      
     </div>
      <DataGrid
      
        
        rows={props.clients[clientIndice].documents}
        columns={columns2.concat(actionColumn)}
        getRowId={(row) => row._id}
     
        localeText={{
 
 toolbarFilters: "Rechercher",

}}
     components={{
 Toolbar: GridToolbarFilterButton ,
}}
      

       
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
       
      />
<React.Fragment>
 
 <Dialog
   open={open}
   onClose={handleClose}
   PaperProps={{
     component: 'form',
     onSubmit: (event) => {
       event.preventDefault();
       const formData = new FormData(event.currentTarget);
       const formJson = Object.fromEntries(formData.entries());
       const email = formJson.email;

       handleClose();
     },
   }}
 >
   <DialogTitle>Modifier le document</DialogTitle>
   <DialogContent>
     <DialogContentText>
     Veuillez inscrire toute modification que vous souhaitez apporter au document
     </DialogContentText>
     <TextField
      InputLabelProps={{ shrink: true }}
       autoFocus
       required
       margin="dense"
       id="name"
       name="email"
       label="Nom du document"
       type="email"
      value={nameState ? undefined: docInfo.name}
       onChange={e => changeDocName(e)}
       fullWidth
       variant="standard"
     />
     <TextField
      InputLabelProps={{ shrink: true }}
       autoFocus
       required
       multiline 
       margin="dense"
       id="description"
       name="description"
       label="Description du document"
       type="text"
       value={descriptionState ? undefined : docInfo.description}
            onChange={e => changeDocDescription(e)}
       fullWidth
       variant="standard"
     />
   </DialogContent>
   <DialogActions>
     <Button onClick={handleClose}>Annuler</Button>
     <Button  variant='contained' onClick={sendForm}>Envoyer</Button>
   </DialogActions>
 </Dialog>
</React.Fragment>

    </div>


    
  );
  
};


};

export default ViewTemplateTable;