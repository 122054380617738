import { useState, useEffect } from "react";
import './HomePageClient.scss'
import {useParams } from "react-router-dom";
import Axios from "axios";
import SidebarClient from "../../component/sideBarClient/SideBarClient";
import Navebar from "../../component/navbar/Navbar";

export default function HomepageClient() {

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const { id } = useParams()

    
    const [data, setData] = useState();


useEffect(() => {
    Axios ({
       method: "GET",
      withCredentials: true,
        url: `${backEndUrl}/auth`,
 
     
      }).then((res) => {
       setData(res.data);
     
       
      });
 }, []) 
   


    
if (data === undefined) {

    return (<>Still loading...</>)
}

if (!data){
    return 
    <h1>Not allowed</h1>
    
} else




// 
    return(
        <div className="home">
        <SidebarClient/>
   

    <div className="homeContainer">
    <Navebar/>

        <div> 
        <div className="titleHome" >
        <h2>Bonjour {data.firstName}</h2>

        <div className="titleHomeKid"> 
       

        <div className="welcomeClient">
        <span > Bienvenue sur votre portail client.  </span>

        </div>

        <div className="welcomeClient">

        <span  ><br/>Merci de téléverser vos documents dans l'onglet 'document' (situé à gauche) et de compléter les formulaires dans la section formulaire (s'il y'a lieu)</span>
        </div>
      
        </div>
        </div>

    </div>
    </div>
    </div>



    )
}