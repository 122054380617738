import './register.css';
import { useState } from "react";
import Axios from "axios";
import { Navigate, useNavigate } from 'react-router-dom';
import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" >
       DocUp
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const defaultTheme = createTheme();

const contract = `

<h4> Introduction </h4>
<br>

Ce Contrat d'Utilisation ("Contrat") est conclu entre DOCUP ("Entreprise") et l'utilisateur ("Utilisateur") qui crée un compte sur notre application  ("Application"). En créant un compte et en utilisant notre Application, l'Utilisateur accepte les termes et conditions énoncés dans ce Contrat.<br><br>

<h3>1. Collecte et Utilisation des Données <br><br></h3>

<h4>1.1 Données Collectées : <br><br></h4>

Informations Personnelles : Cela inclut, mais ne se limite pas à : le nom, l'adresse e-mail, les coordonnées et toute autre information fournie par l'Utilisateur lors de la création du compte ou de l'utilisation de l'Application.<br><br>
Informations sur les Clients : Cela inclut les documents et les données soumis par les clients de l'Utilisateur dans le cadre des services fournis via l'Application.<br><br>
Données d'Utilisation : Cela inclut des informations sur la manière dont l'Utilisateur interagit avec l'Application, y compris les adresses IP, les types de navigateurs et les heures d'accès.<br><br>

<h4>1.2 Utilisation des Données :<br><br></h4>

Voici le cadre dans lequel nous utilisons vos données :<br><br>
- Pour fournir, exploiter et maintenir notre Application.<br><br>
- Pour améliorer, personnaliser et développer notre Application.<br><br>
- Pour comprendre et analyser comment l'Utilisateur utilise notre Application.<br><br>
- Pour développer de nouveaux produits, services, fonctionnalités et fonctionnalités.<br><br>
- Pour communiquer avec l'Utilisateur, y compris pour le service client, les mises à jour et autres informations relatives à l'Application.<br><br>
- Pour traiter les transactions et gérer les abonnements.<br><br>
- Pour détecter et prévenir les fraudes et autres activités illégales.<br><br>

<h3> 2. Limites d'Utilisation des Données<br><br> </h3>

<h4> 2.1 Utilisations Interdites : <br><br> </h4>

<span style="font-weight: bold; color: #333"> Nous n'utiliserons jamais vos données pour :<br><br> </span>

- Engager des activités commerciales liées à l'industrie de l'Utilisateur, y compris, mais sans s'y limiter, les services d'immigration ou autres services lié à la profession de l’utilisateur.<br><br>
- Contacter les clients de l’utilisateur pour tout autre raison qu'une demande soutien technique faite par l'Utilisateur. <br><br>
- Vendre ou louer des données personnelles à des tiers.<br><br>
- Partager des données personnelles à des tiers autre que ceux énuméré aux points 3.1<br><br>
- Utiliser les données à des fins autres que celles spécifiées dans ce Contrat sans le consentement explicite de l'Utilisateur.<br><br>



<h3>3. Protection et Sécurité des Données <br><br></h3>

<h4>3.1 Sécurité des Données : </h4> <br> Nous mettons en œuvre des mesures techniques et organisationnelles appropriées pour garantir un niveau de sécurité adapté au risque de traitement des données personnelles. Cela inclut sans se limiter le chiffrement, les contrôles d'accès et les audits de sécurité régulières.<br><br>

<h4>3.2 Conservation des Données :</h4> <br> Nous conserverons les données personnelles uniquement aussi longtemps que nécessaire pour atteindre les objectifs pour lesquels elles ont été collectées, c’est à dire assurer le bon fonctionnement des différentes fonctionnalités de l’application. L’Utilisateur peut à tout moment, en supprimant son compte, procéder à la suppression intégrale de ses données sur nos serveurs (informations et documents).<br><br>

<h4> 3.3 Lieu des Données : </h4> <br> Toutes les données sont stockés en Amérique du Nord chez un hebergeur agréé par les standards de l'industrie (FedRAMP, la Directive des données de l'UE, FISMA ...)<br><br>

<h3> 4. Partage et Divulgation des Données<br><br> </h3>

<h4> 4.1 Prestataires de Services Tiers :</h4> <br> Nous pouvons partager des données avec des prestataires de services tiers qui effectuent des services en notre nom, tels que le traitement des paiements, l'analyse des données et la livraison de courriels. Les prestataires utilisés sont des leaders de l’industrie et sont tenus de maintenir la confidentialité et la sécurité des données et de les utiliser uniquement aux fins pour lesquelles elles ont été divulguées.<br><br>

<h4> 4.2 Exigences Légales : </h4> <br> Nous pouvons divulguer des données seulement si la loi l'exige ou en réponse à des demandes légitimes des autorités publiques.<br><br>


<h3> 5. Responsabilités de l'Utilisateur <br><br> </h3>

<h4> 5.1 Sécurité du Compte : </h4> <br> L'Utilisateur est responsable de maintenir la confidentialité de ses identifiants de compte et de toutes les activités qui se produisent sous son compte.<br><br> 

<h4> 5.2 Respect des Lois : </h4> <br> L'Utilisateur est responsable de respecter toutes les lois applicables concernant la collecte et le traitement des données.<br><br> </h4>


<h3> 6. Résiliation <br><br> </h3>

<h4> 6.1 Résiliation par l'Utilisateur : </h4> <br> L'Utilisateur peut résilier ce Contrat à tout moment en fermant son compte et en cessant d'utiliser l'Application. Dans le cadre de la suppression de son compte, toutes ses données seront supprimés. <br><br> 

<h4> 6.2 Résiliation par l'Entreprise : </h4> <br> L'Entreprise peut résilier ce Contrat et l'accès de l'Utilisateur à l'Application à tout moment, avec ou sans motif, avec ou sans préavis.<br><br> </h4>

<h3> 7. Droit Applicable<br><br> </h3>

Ce Contrat est régi et interprété conformément aux lois du Canada, sans égard à ses principes de conflit de lois.<br><br>

<h3> 8. Garanties<br><br> </h3>

Nous attachons une grande importance à votre satisfaction. Bien que nous ayons déployé des efforts considérables pour développer et maintenir les infrastructures lié à l’application, en devenant Utilisateur, vous acceptez de ne pas tenir DocUp responsable des problèmes techniques, y compris la perte de vos fichiers (Données Client). Nous mettons à votre disposition des outils pour télécharger régulièrement des sauvegardes de vos fichiers et vous encourageons fortement à utiliser cette fonctionnalité.
Les données sont fournis par DocUp "tels quels" et "selon disponibilité". Dans toute la mesure permise par la loi, DocUp ne fait aucune représentation ni garantie de quelque nature que ce soit, expresse ou implicite, concernant l'utilisation ou la performance des infrastructures, y compris leur précision, fiabilité ou autres aspects. DocUp ne sera pas responsable de toute interruption ou perturbation de l'utilisation des infrastructures. Toutes les garanties, y compris les garanties implicites de qualité marchande, d'adéquation à un usage particulier et de non-contrefaçon, sont par la présente déclinées.<br><br>

<h3> 9. Sécurité du Réseau et de l'Information<br><br> </h3>

Assurer la sécurité de vos données est une priorité pour nous. DocUp met en œuvre des mesures complètes pour protéger les informations que nous recueillons auprès de vous, afin de prévenir la perte, l'utilisation abusive et l'accès, la divulgation, l'altération et la destruction non autorisés. Les informations personnelles sensibles, telles que les numéros de carte de crédit, les mots de passe et les données de vos clients, sont cryptées lors de leur transmission sur Internet à l'aide du protocole Secure Socket Layer (SSL).<br><br>

Nos serveurs sont situés dans un environnement contrôlé avec un accès restreint. Bien que nous employions des mesures robustes pour protéger les informations personnelles collectées directement auprès de vous, aucun système de sécurité n'est totalement infaillible.<br><br>

L'accès à votre compte et à nos services est limité par des identifiants utilisateur individuels et des mots de passe. Pour garantir la confidentialité de vos informations personnelles, vous devez garder votre mot de passe sécurisé et ne pas le partager avec qui que ce soit. Informez-nous immédiatement si vous pensez que votre mot de passe a été compromis. De plus, déconnectez-vous toujours et fermez votre navigateur à la fin de votre session. Veuillez noter que nous ne vous demanderons jamais de divulguer votre mot de passe dans un appel téléphonique ou un courriel non sollicité.<br><br>

<h3> 10. Amendements<br><br> </h3>
L'Entreprise se réserve le droit de modifier ce Contrat à tout moment. L'Utilisateur sera informé de tout changement et l'utilisation continue de l'Application après une telle notification constitue l'acceptation des termes modifiés.<br><br>

<h3> 11. Clause de Modification<br><br> </h3>

Nous nous réservons le droit de modifier les tarifs, les fonctionnalités et les limitations de nos forfaits à tout moment. Toute modification des tarifs ou des forfaits sera communiquée aux utilisateurs par des canaux appropriés, tels que des courriels ou des notifications au sein de l'application. La poursuite de l'utilisation du service après de telles modifications constituera votre consentement à ces changements.
<br><br>
<h3> 11. Coordonnées<br><br> </h3>

Pour toute question ou préoccupation concernant ce Contrat, veuillez nous contacter à support@docup-app.com.


`;

export default function Register() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [registerUsername, setRegisterUsername] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setRegisterConfirmPassword] = useState("");
  const [registerName, setRegisterName] = useState("");
  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);
  const [userExist, setUserExist] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');
  const [error, setError] = useState('');

const handleSubmit = async (e) => {
  
}

const handlePasswordChange = (e) => {
  setRegisterPassword(e.target.value);
};

const showContract = (type) => () => {
  setOpen(true);
  setScroll(type);

};

const handleClose = () => {
  setOpen(false);
};

const descriptionElementRef = React.useRef(null);
React.useEffect(() => {
  if (open) {
    const { current: descriptionElement } = descriptionElementRef;
    if (descriptionElement !== null) {
      descriptionElement.focus();
    }
  }
}, [open]);

const handleConfirmPasswordChange = (e) => {
  setRegisterConfirmPassword(e.target.value);
};

const register = async (e) => {
  e.preventDefault()

  setUserExist(false)
  setEmailExist(false)
  

  const isValidPassword = /^(?=.*[A-Z])(?=.*\d).{6,}$/.test(registerPassword);


  if (!isValidPassword) {
    setError("Votre mot de passe doit contenir au moins 6 caractères et une lettre majuscule ainsi qu'un chiffre. ");
    return;
  }

  // Check if passwords match
  if (registerPassword !== confirmPassword) {
    setError('Les mots de passe ne correspondent pas. Veuillez réessayer.');
    return;
  }


  if (!acceptTerms) {
    setError("Vous devez accepter les contrats d'utilisation.");
    return;
  }
  // All validations passed, you can proceed with your form submission or other actions here
  setError('');

  try {
    const response = await Axios({
    method: "POST",
    data: {
      consultantUsername: registerUsername,
      consultant_Password: registerPassword,
      consultant_name: registerName,
   
      email: email,

    },
    withCredentials: true,
    url: `${backEndUrl}/users`,
  }
  )

  
  if (response.status === 200) {

    // Handle success (e.g., show a success message to the user)
    setValidated(true); // Set success to true if registration is successful
    setUserExist(false)
    
  }else if (response.status === 204) {
    setUserExist(true)
    setValidated(false)
    
    
    // Handle the case where the user already exists (e.g., show an error message to the user)
  } else if (response.status === 205) {
    setEmailExist(true)
    setValidated(false)
  }


} catch (error) {
  alert("Error during registration:", error);
  console.error("Error during registration:", error);
}
};


  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
           // ITALY backgroundImage: 'url(https://images.pexels.com/photos/17850754/pexels-photo-17850754/free-photo-of-buildings-around-narrow-canal-in-town.jpeg)',
            backgroundImage: 'url(https://images.pexels.com/photos/2529179/pexels-photo-2529179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              <AccountCircleIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Créer votre compte
            </Typography>
            <Box component="form" onSubmit={register} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="username"
                label="Username"
                type="text"
                id="username"
                autoComplete="current-password"
                onChange={(e) => setRegisterUsername(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                id="password"
                autoComplete="current-password"
                helperText='Votre mot de passe doit contenir au moins 6 caractères et une lettre majuscule.'
                 onChange={handlePasswordChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="confirmPassword"
                label="Confirmer mot de passe"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                onChange={handleConfirmPasswordChange}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Addresse e-mail"
                name="email"
                autoComplete="email"
             
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="name"
                label="Nom et prénom"
                type="text"
                id="name"
                autoComplete="current-password"
                onChange={(e) => setRegisterName(e.target.value)}
              />

<FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onChange={(e) => setAcceptTerms(e.target.checked)}
                    name="acceptTerms"
                    color="primary"
                  />
                }
                label={<span>J'ai lu, je comprend et j'accepte le <Link onClick={showContract('paper')} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>contrat d'utilisation</Link></span>}
              />
          
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                S'inscrire
              </Button>
              <Grid container>
              {validated && <span style={{color:"green", marginTop:"10px", fontSize: "20px"}}>Compte crée ! Merci de retourner à l'écran d'acceuil <a href="https://docup-visa.com">ici</a></span>}
        {userExist && <span style={{color:"red", marginTop:"10px", fontSize: "20px"}}>Username déjà pris, merci d'en choisir un autre </span>}
        {emailExist && <span style={{color:"red", marginTop:"10px", fontSize: "20px"}}>L'e-mail existe déjà dans la base de donné </span>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
             
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>


        <React.Fragment>
  
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ '& .MuiDialog-paper': { width: '80%', maxWidth: 'none' } }}
      >
        <DialogTitle id="scroll-dialog-title">Contrat d'utilisation</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
          <div dangerouslySetInnerHTML={{ __html: contract }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button variant="contained" size='small' onClick={handleClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>


      </Grid>
    </ThemeProvider>

    
  );

 
}