import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Axios from 'axios';


import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';



function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignIn() {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [email, setEmail] = React.useState('');


const handleSubmit = () => { 
  Axios({
    method: "POST",
    data: {
      email: email,
   
    },
    withCredentials: true,
    url: `${backEndUrl}/update/resetPasswordRequest`,

    
  }).then((res)  => 
 
 res.data !='No User Exists' ? 
  alert('Un e-mail a été envoyé à votre adresse e-mail. Veuillez vérifier votre boîte de réception.') : alert('Aucun utilisateur avec cette adresse e-mail n\'a été trouvé.')
    )
   
};



  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
           Mot de passe oublié ?
          </Typography>
          <p style={{marginTop : '8%', marginBottom:'3%'}}>Veuillez inscrire votre e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.</p>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
        
       
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
           
            >
              Envoyer
            </Button>
            <Grid container>
              <Grid item xs>
            
              </Grid>
              <Grid item>
          
              </Grid>
            </Grid>
          </Box>
        </Box>
     
      </Container>
    </ThemeProvider>
  );
}