import React from "react";
import { Link } from "react-router-dom";
import Axios from "axios";


export default function Client(props) {

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

const deleteClient = async () => {
  
 

  try {
      await Axios ({
        method: "DELETE",
        withCredentials: true,
        data: {
            id: props.rid,

        },
         url : `${backEndUrl}/updateClient/deleteClient`  ,
    })
  } catch (error) {
    
  }
}

    return (
      <div className="header">
       <p>{props.name}</p>
        <Link to={`/user/${props.rid}`}>Go to client</Link>
        <form onSubmit={deleteClient}>
        <button type="submit">Delete client</button>
        </form>
        <p>{props.rid}</p>
      </div>
    );
  }

