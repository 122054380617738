import React from "react";
import "./FakeIndi.scss"
import Datatable from "../../component/datatable/Datatable";
import { useNavigate } from "react-router-dom";
import Axios from "axios";
import { useState, useEffect } from "react";
import UserDoc from "../../component/usersDoc/UsersDoc";
import { useParams } from "react-router-dom";
import TableDoc from "../../component/TableDoc/TableDoc";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import DocReview from "../../component/docReview/docReview";
import DocForEmail from "../../component/docForEmail/docForEmail";
import WrongDocs from "../../component/wrongDocs/wrongDocs";
import Button from '@mui/material/Button';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; 
import Chip from '@mui/material/Chip';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import FormToComplete from '../../component/formToComplete/formToComplete'
import TextField from '@mui/material/TextField';

import CircularProgress from '@mui/material/CircularProgress';



import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

//shit about tabs 

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


export default function Fake () {

 
  const [isEditing, setIsEditing] = useState(false);



  const handleModifyClick = () => {
    setIsEditing(true);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleSaveClick = async () => {

    
    try {
      await Axios ({
          method: "PUT",
          withCredentials: true,
          data: {
            note: note, 
            client_id: data.clients[clientIndice]._id, 
          },
          
           url : `${backEndUrl}/updateClient/note/` 
        
      })
    } catch (error) {
      
    }

    setIsEditing(false);
  };

  const [inputText, setInputText] = useState('');

  const handleChangei = (event) => {
    setInputText(event.target.value);
  };

    const [data, setData] = useState();
  
    const [note, setNote] = useState('');
    const { id } = useParams()
    const [buttonText, setNewText] = useState("Edit name");
    const [toggle, setToggle] = useState(false);
    const [clientNewName, setNewName] = useState("");

    const [docNewName, setNewDocName] = useState("");
    const [docNewDes, setNewDocDes] = useState("");

    const [docName, setDocName] = useState("");
    const [docDescription, setDocDescription] = useState("");
    
    const [deadline, setDeadline] = useState("");

    const [toggleNewDoc, setToggleNewDoc] = useState(false);
    const [buttonAddDoc, setNewTextAddDoc] = useState("Demander un autre document");
    const [newDeadline, setNewDeadline] = useState("");
    const [displayDeadline, setToggleNewdeadline] = useState(false);
    const [notUploadedDoc, setNotUploadedDocuments] = useState("");
    const [emailSent, setEmailSent] = useState(false);
   // const [value, setValue] = useState("");

   const [allFormsCompleted, setAllFormsCompleted] = useState(false);
   const [selectedDate, setSelectedDate] = useState(null);
   const [warningDate, setWarningDate] = useState(false);
   

   
// Stuff for confirmation window
   const [openNewDoc, setOpenNewDoc] = React.useState(false);

/// Stuff for confirmation window

const [askConfirmationNewDoc, setAskConfirmationNewDoc] = React.useState(false);

   const handleConfirmationNewDoc = () => {
    setAskConfirmationNewDoc(true);
  };
   const handleCloseConfirmationNewDoc = () => {
    setAskConfirmationNewDoc(false);
  };

  ///

  const handleClickOpenNewDoc = () => {
    const currentDate = dayjs.utc();

    setDeadline(dayjs.utc(data.clients[clientIndice].dateDeadline));


    if (currentDate.isAfter(data.clients[clientIndice].dateDeadline)) {
      setWarningDate(true);
      } else {
        setWarningDate(false);
      }

    setOpenNewDoc(true);
  };

  const handleCloseNewDoc = () => {
    setOpenNewDoc(false);
  };


  const handleCloseConfirmation = () => {
    setEmailSent(false); // Reset emailSent state to false when the confirmation window is closed
  }




  // End Stuff for confirmation window

   const handleDateChange = (date) => {
    setDeadline(date); // Save the selected date in the state variable
  };

var li = 0;

const [openEmailConfirmation, setOpenEmailConfirmation] = React.useState(false);

const handleClickOpenEmailConfirmation = () => {
  setOpenEmailConfirmation(true);
};

const handleCloseEmailConfirmation = () => {
  setOpenEmailConfirmation(false);
  setEmailSent(false);
};




// shit about tab display 

const [value, setValue] = React.useState(0);
const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};

const handleChange = (event, newValue) => {
  setValue(newValue);
};

const handleStatusChange = async () => {
  try {
      
    await Axios ({
        method: "PUT",
        withCredentials: true,
        data: {
          client_id: data.clients[clientIndice]._id, 
        },
        
         url : `${backEndUrl}/updateClient/statusChange/` 
      
    })
    window.location.reload();
  } catch (error) {
    
  }
}

const sendForm = () => {
  setOpenNewDoc(false);

  Axios.post(`${backEndUrl}/updateClient/addDoc`, {
   doc_name: docName,
    doc_description: docDescription,
   client_id: data.clients[clientIndice]._id,
  deadline: deadline
  },
  {
    withCredentials: true, 
  }
  )
  .then(response => {
    
   window.location.reload();
  })
  .catch(error => {
    console.error("Error:", error);
    // Handle the error here
  });
}

const askNewDocAndSendEmail = () => {

  const newDoc = {
    "name": docName,
    "description": docDescription,
    "doc_upload": "",
    "doc_upload_consultantSide": "",
    "attachment_ind": false,
    "status": "not uploaded",
    "review": "",
    "date": null,
    "size": 0,
    "newDoc": true,
    "_id": "new_unique_id" // Ensure to generate a unique ID if necessary
  };

notUploadedDocs.push(newDoc);

  setOpenNewDoc(false);

  Axios.post(`${backEndUrl}/updateClient/askNewDocAndSendEmail`, {
   doc_name: notUploadedDocs,
   client_id: data.clients[clientIndice]._id,
   deadline:  new Date(deadline,).toLocaleDateString('en-GB'),
   client_name: data.clients[clientIndice].client_firstName + " " + data.clients[clientIndice].client_lastName, 
   client_email: data.clients[clientIndice].client_Email,
   consultant_email: data.email,

  },
  {
    withCredentials: true, 
  })
  .then(response => {
    
   window.location.reload();
  })
  .catch(error => {
    console.error("Error:", error);
    // Handle the error here
  });
}


const changeDeadline = async () => {
  try {

    const today = new Date();
    const selectedDateObject = new Date(deadline);

    if (selectedDateObject < today) {
      // Display an alert if selectedDate is smaller
      alert("Merci de sélectionner une date postérieur à aujourd'hui");
      return; // Exit the function without making the Axios request
    }


    await Axios ({
        method: "PUT",
        withCredentials: true,
        data: {
          newDate: selectedDateObject, 
          client_id: data.clients[clientIndice]._id, 
          status: data.clients[clientIndice].fileStatus
        },
        
         url : `${backEndUrl}/updateClient/deadlineChange/`
      
    }).then(() => {
    // window.location.reload();
    })
  } catch (error) {
    
  }
}






    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
          url: `${backEndUrl}/auth`,
     
         
          }).then((res) => {
           setData(res.data);
     
          
           
          });
     }, []) 

     const showUpdate = (e) => {
        e.preventDefault();
        setToggle(!toggle);
        if (toggle) setNewText("Edit name");
        else  
        setNewText("Hide edit");
      };

      


var notUploadedDocs = [];
  

      const sendEmailMissingDoc = async () => {
       //  
        try {
      
         const response = await Axios ({
              method: "POST",
              withCredentials: true,
              data: {
                  client_name: data.clients[clientIndice].client_firstName + " " + data.clients[clientIndice].client_lastName, 
                  client_email: data.clients[clientIndice].client_Email,
                  consultant_email: data.email,
                  missing_doc : notUploadedDocs,
                  deadline: new Date(data.clients[clientIndice].dateDeadline).toLocaleDateString('en-GB'),
              },
              
               url : `${backEndUrl}/updateClient/sendEmailMissing` ,
            
          },

          )
          if (response.status === 200) {
            setEmailSent(true);
            setTimeout(() => {
              setEmailSent(false);
              window.location.reload();
              
            }, 3000);
         

          }

        } catch (error) {
          
        }
      }


      // function to update the client name
      const updateClient = async () => {
        try {
      
          await Axios ({
              method: "PUT",
              withCredentials: true,
              data: {
                  client_name: clientNewName, 
              },
              
               url : `${backEndUrl}/updateClient/name/` + data.clients[clientIndice]._id ,
            
          })
      
        } catch (error) {
          
        }
      }

      // to do : replace the strings here by the value. In this case, you need to use useState to store the value of the input. Also need to toogle the update input if cliked.

      const addDocument = async () => {

        

        if (data.clients[clientIndice].documents.length > 20)
          {
            alert("Vous ne pouvez pas ajouter plus de 20 documents")
            return;
          }


        try {

       
      
          await Axios ({
              method: "POST",
              withCredentials: true,
              data: {
                 client_id: data.clients[clientIndice]._id,
                  doc_name : docNewName,
                  doc_description: docNewDes,
              },
              
               url : `${backEndUrl}/updateClient/addDoc` ,
            
          }).then(() => {
         
            window.location.reload();
          })
      
        } catch (error) {
          
        }
      }


      // create a function to update the fileStatus of the client

      const updateFileStatus = async () => {
        try {
          await Axios ({
            method: "PUT",
            withCredentials: true,
            data: {
               client_id: data.clients[clientIndice]._id,
               //newDeadline: newDeadline,
               
            },
            
             url : `${backEndUrl}/updateClient/ChangeStatusIfOnlyForms` ,
          
        })

         window.location.reload();
    
      } catch (error) {
        
      }
    }










      const newDeadLine = async () => {
    
        try {

          await Axios ({
              method: "PUT",
              withCredentials: true,
              data: {
                 client_id: data.clients[clientIndice]._id,
                 //newDeadline: newDeadline,
                 newDeadline: value,
              },
              
               url : `${backEndUrl}/updateClient/newDeadline` ,
            
          })

          // window.location.reload();
      
        } catch (error) {
          
        }
      }
      

      const sendEmail = async () => {
      

        try {
      


          await Axios ({
              method: "POST",
              withCredentials: true,
              data: {
                clientEmail : data.clients[clientIndice].client_Email,
                clientName : data.clients[clientIndice].client_name,
                consultantEmail : data.email,

              },

                url : `${backEndUrl}/users/sendEmail` ,

          })

          // window.location.reload();

        } catch (error) {
          
        }
      }


  

  function displayDocumentsMissing(doc) {
    return <DocForEmail
        name={doc.name}
        description={doc.description}
        status={doc.status}
        review={doc.review}
        reason={doc.reason}
    />
} 

   
useEffect(() => {
  if (data) {
    // Find the client that matches the id
    const client = data.clients.find(client => client._id === id);
    if (client) {
      setNote(client.note);
    }
  }
}, [data, id]);
 


    if (data === undefined) {

        return (

          <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  
        )
    }
    
    if (!data){
        return 
        <h1>Not allowed</h1>
        
    } 

    if (data){

      
      var clientIndice;

        // when we have data, we need to find the client that matches the id
     for (let i = 0; i < data.clients.length; i++) {
        if (data.clients[i]._id === id) {
            // when we find the client, we need to store the indice of the client in the array
     
            // which then become the clientIndice i that we can use to render HTML
            var clientIndice = i;
           
        
            break
        }
    }





        var notUploadedDocs = data.clients[clientIndice].documents.filter(doc => doc.status === 'not uploaded');
       

   
        const translateStatus = (status) => {
          switch (status) {
            case "uncomplete":
              return "incomplet";
            case "late":
              return "Retard";
            case "complete":
              return "Complet";
            // Add more cases if needed
            default:
              return status; // Return the original status if no translation is found
          }
        };

    let statusClassName = "status"

    if (data.clients[clientIndice].fileStatus === "uncomplete") {
      statusClassName = "warning";
    } else if (data.clients[clientIndice].fileStatus === "complete") {
      statusClassName = "success";
    } else if (data.clients[clientIndice].fileStatus === "client to notify") {
      statusClassName = "toNotify";
    }
    else if (data.clients[clientIndice].fileStatus=== "to review") {
      statusClassName = "toRev";
    }
    else if (data.clients[clientIndice].fileStatus=== "late") {
      statusClassName = "error";
    }

    return (
        <div>


         

            <div className="home">
            <Sidebar
               storageUsed={data.storageUsed}
            />
       

        <div className="homeContainer">
        <Navebar
            userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
        />
        
        <div className="clientContainer">

       
      <div className="nameStatus">
      
             <h1> {data.clients[clientIndice].client_firstName}  {data.clients[clientIndice].client_lastName}</h1>

            
             <p className={statusClassName}>

             <Chip style={{marginLeft:'7%'}} size="small" label={translateStatus(data.clients[clientIndice].fileStatus)} color={statusClassName} />

</p>
      </div>
             
             <div>

<Box sx={{ width: '100%' , marginTop:'1%'}}>
<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
  <Tab label="Document" {...a11yProps(0)} />
{/*   <Tab label="Formulaire" {...a11yProps(1)} /> */}
  <Tab label="Information" {...a11yProps(1)} />
</Tabs>
</Box>
<CustomTabPanel value={value} index={0}>



        



          <div className="addDoc">

          <div>
          <Button style={{ marginLeft: '-15%' , marginBottom: '2%' , marginTop: '1%'}} variant='contained' onClick={handleClickOpenNewDoc} >Demander nouveau document </Button>

          {data.emailCount >=100 && data.subscription==='free' ? 
          
          <Button style={{ marginLeft: '55%',  marginBottom: '2%' , marginTop: '1%'}} disabled variant="contained" onClick={handleClickOpenEmailConfirmation}>Envoyer e-mail</Button>
          : 
          
          <Button style={{ marginLeft: '55%',  marginBottom: '2%' , marginTop: '1%'}} variant="contained" onClick={handleClickOpenEmailConfirmation}>Envoyer e-mail</Button>
           }
          </div>

         {/*  windows for send e-mail confirmation */}

         <React.Fragment>
     
      <Dialog
        open={openEmailConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Envoyer cet e-mail ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
       
          
            Voici l'email qui sera envoyé à votre client : 

        <br/>
        <br/>
        Bonjour {data.clients[clientIndice].client_firstName} {data.clients[clientIndice].client_lastName},
        <br/>
      
        <br/>
        Merci de téléverser les documents suivants sur votre compte DocUp disponible à travers le lien suivant : https://docup-visa.com/login
        <br/>
        <br/>
        {data.clients[clientIndice].documents.map(displayDocumentsMissing)}
        <br/>
        <br/>
        Merci de téléverser les documents manquants avant le <strong> {new Date(data.clients[clientIndice].dateDeadline).toLocaleDateString('en-GB')}</strong>.
        <br/>
        <br/>
        Merci, 
        <br/>
        <br/>
        {emailSent ? <p style={{color: 'green', fontSize: '20px' }}>Votre email a bien été envoyé</p> : null}
     

          </DialogContentText>
        </DialogContent>
        <DialogActions onClose={handleCloseConfirmation}>
          <Button variant='outlined' size='small' onClick={handleCloseEmailConfirmation}>Annuler</Button>
          <Button variant="contained" size="small" onClick={sendEmailMissingDoc} autoFocus>
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>



         {/*  windows for send e-mail confirmation */}

          <React.Fragment>
          <Dialog
        open={openNewDoc}
        onClose={handleCloseNewDoc}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}
      >
        <DialogTitle>Demander un nouveau document</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Veuillez entrer le nom et la description du nouveau document. 
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label="Nom du document"
            type="text"
            onChange={e => setDocName(e.target.value)}
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="description"
            name="description"
            label="Description du document"
            type="text"
            multiline
            onChange={e => setDocDescription(e.target.value)}
            fullWidth
            variant="standard"
            style={{marginTop:'5%'}}
          />
           <LocalizationProvider  dateAdapter={AdapterDayjs}>
           <div  style={{ marginTop:'5%'}}>
           <label style={{ marginRight:'5%'}} >Date limite : </label>
      <DatePicker

      value={deadline}
      format="YYYY/MM/DD" 
      onChange={handleDateChange}
       />
       </div>
       {warningDate ? <p style={{color: 'red', fontSize: '15px', marginTop:'4%' }}>ATTENTION : La date limite est passée, veuillez choisir une date postérieur à aujourd'hui.</p> : null}
    </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' size='small' onClick={handleCloseNewDoc}>Annuler</Button>
 {/*          <Button onClick={sendForm}>Valider</Button> */}
          <Button variant='contained' size='small' onClick={handleConfirmationNewDoc}>Valider</Button> 

        </DialogActions>
      </Dialog>
    </React.Fragment>



{/* This one is to say notify client now ? */}
    <React.Fragment>
          <Dialog
        open={askConfirmationNewDoc}
        onClose={handleCloseConfirmationNewDoc}
        maxWidth='md'
 
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            
            handleClose();
          },
        }}

      >
        <DialogTitle>Notifier le client ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
       
          Souhaitez-vous notifier le client par e-mail maintenant ou poursuivre votre vérification ?
       
          </DialogContentText>
        
           <LocalizationProvider  dateAdapter={AdapterDayjs}>
  
    
    </LocalizationProvider>
        </DialogContent>
        <DialogActions>
    {/*       <Button variant='outlined' size='small' onClick={handleCloseNewDoc}>Plus tard</Button> */}
          <Button  size='small' style={{marginRight:'45%'}} onClick={handleCloseConfirmationNewDoc}>Annuler</Button>
          <Button variant='outlined' size='small' onClick={sendForm}>Plus tard</Button>
          <Button variant='contained' size='small' onClick={askNewDocAndSendEmail}>Envoyer e-mail</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>


          {toggleNewDoc ? (
            <div className="addDocForm">
                <form onSubmit={addDocument}>
            
                <label>Nom du document</label>
                <input className="addDocFormInput" type="text" onChange={(e) => setNewDocName(e.target.value)} />
               
               
                <label className="LabelInput">Description du document</label>
                <input  className="addDocFormInput" type="text" onChange={(e) => setNewDocDes(e.target.value)} />
               
               
                <Button  variant="contained"  size="small" className="LabelInput" type="submit"> Valider</Button>
             
                </form>
                </div>
                ) : null}

            {toggle ? (
                <form onSubmit={updateClient}>
                <div>
                <label>New client's name</label>
                <input type="text" onChange={(e) => setNewName(e.target.value)} />
                <button type="submit"> Update</button>
                </div>
                </form>
                ) : null}

          </div>

         
          {emailSent ? <p>Email sent</p> : null}

<TableDoc 
              clients={data.clients[clientIndice].documents}
              deadline={data.clients[clientIndice].dateDeadline}
             
              rid={data.id}
              getRowId={(row) => row.data.clients._id}
              ridi={data.clients[clientIndice]._id}
              storageUsed={data.storageUsed}
           
        /> 




          <div className="email2">

  


</div> 


</CustomTabPanel>

<CustomTabPanel value={value} index={1}>

<h3 style={{marginTop:'1%'}}>Informations du client: </h3>

<div className="containerrr">



<span className="infoColumn">

<div className="infoColumnSpacing">
Prénom : {data.clients[clientIndice].client_firstName}
</div>
<div className="infoColumnSpacing">
Nom : {data.clients[clientIndice].client_lastName}
</div>
<div className="infoColumnSpacing">
Username : {data.clients[clientIndice].client_Username}
</div>
<div className="infoColumnSpacing">
Pasword : {data.clients[clientIndice].client_Password}
</div>

<div className="infoColumnSpacing">
E-mail : {data.clients[clientIndice].client_Email}
</div>
<div className="infoColumnSpacing">

<div>
Date limite : {(data.clients[clientIndice].dateDeadline).substring(0, 10)}

</div>


<div>



<form onSubmit={changeDeadline}>




  <p style={{marginTop:'3%'}}>Modifier la date limite: </p>




<div style={{marginTop:'1%'}}>
  <LocalizationProvider  dateAdapter={AdapterDayjs}>
      <DatePicker
     
      onChange={handleDateChange}
      format="YYYY/MM/DD" 
       />
    </LocalizationProvider>

</div>



<Button variant='outlined' size='small' style={{marginTop:'1%', marginLeft:'7%'}} type="submit">Enregistrer</Button>

</form>
</div>

</div>


</span>

<div className="general">


<div className="notes">
{isEditing ? (
        <div className="noteModifier">
        <TextField
            label="Note"
            multiline
            rows={9}
            value={note}
            onChange={handleNoteChange}
            variant="outlined"
            fullWidth
            style={{ width: "600px" }}
          />
          <Button 
           variant="outlined"
            style={{ 
      marginTop: '20px', 
      marginRight: '20px',
      alignSelf: 'flex-end',
     
    }}
          className="saveButton" onClick={handleSaveClick}>Enregistrer</Button>
        </div>
      ) : (
        <div className="noteModifier">
          <p style={{ whiteSpace: 'pre-wrap' }} >Note: {note}</p>

          
          <Button   
          variant="outlined"
            style={{ 
      marginTop: '20px', 
      marginRight: '20px',
      alignSelf: 'flex-end',
     
    }} onClick={handleModifyClick}>Modifier</Button>
        </div>
      )}
</div>
</div>
</div>
</CustomTabPanel>
</Box>

</div>
          

            </div>

       

          

      

     


      
     
    
      {/* {data.clients[clientIndice].documents.map(displayDocuments)}  */}

        </div>

    

        {/* <div className="deadlineContainer"> */}

         

{/* <form >

  <Button  
 variant="outlined"
onClick={showDeadline}>Set up new deadline</Button>

 
   <Button 
   variant="outlined"
   sx={
    {
      marginLeft: '15px',
   }
  }
    onClick={sendEmail}>Send Email now </Button>

     {displayDeadline ? (
      <form onClick={newDeadLine}>
      <div className="monster">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker value={value} onChange={(newValue) => setValue(newValue)} />
      </LocalizationProvider>
      <Button 
      sx={
        {
          marginLeft: '15px',
      }
      }
      variant="contained" type="submit"> Update deadline</Button>
      </div>
      </form>
      ) : null}
 </form> */}
 {/* </div> */}

      
        </div>
     
  
            
  

   
           
    
        </div>
    )
    }
}