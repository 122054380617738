import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './askForUpgrade.scss'; // Optional CSS file for styling

const SimpleUpgradePage = () => {
  
    const { userEmail } = useParams();

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


    const handleUpgrade = async () => {
        try {
          const response = await fetch(`${backEndUrl}/users/create-checkout-session`, {
            method: 'POST',
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              consultantEmail: userEmail,
              // consultantID: data.id,
            }),
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
      
          //const data = await response.json();
          const body = await response.json()
          // Handle the response data
          //
          window.location.href = body.url
        } catch (error) {
          console.error('Error:', error);
        }
      };
      
    return (
        <div className="upgrade-container">
            <h1>Upgrade Required</h1>
            <p>Cher {userEmail},</p>
            <p>
            Vous avez atteint la limite maximale de 3 clients sur votre plan gratuit. Pour continuer à avoir accès à la plateforme, veuillez passer à un plan pro.
                
            </p>
            <button onClick={handleUpgrade} className="upgrade-button">
                Upgrade Now
            </button>
        </div>
    );
};

export default SimpleUpgradePage;
