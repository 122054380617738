import { useSlotProps } from "@mui/base"
import "./wrongDocs.scss"


const DocForEmail = (props) => {

 
    
        return (
      

       
          
<div className="docForEmail">
      {props.review === "bad" ? (
        <div className="reasonMap">
        
       <p><strong>{props.name}</strong> : {props.reason}</p>
        </div>
      ) : null}

    
    </div>
           
        )
}

export default DocForEmail;