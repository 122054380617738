import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { useParams, useLocation} from 'react-router-dom';
import "./CreateClient.scss";
import Input from "../../component/input/Input";
import Axios from "axios";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import { Button, TextField } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';

import { driver } from "driver.js";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));



export default function NewClient() {



  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS
  const { id } = useParams();
  const location = useLocation();


    const [numberOfDocs, setNumberDoc] = useState(0);

    const [clientLimit, setClientLimit] = useState(false);
    const [DocLimit, setDocLimit] = useState(false);

    const [data, setData] = useState();

    const [fileName, setFileName] = useState([]);

    const [newName, setNewFirstName] = useState("");
    const [newLastName, setNewLastName] = useState("aa");
    const [newDocs, setNewDocs] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [emailError, setValidationEmailError] = useState(false);
    const [newDate, setNewDate] = useState("");

    const [accepted, setAccepted] = useState(false);
    const [newDocName, setNewDocName] = useState("");
    const [program, setProgram] = useState("");
  
    const [newDocDes, setNewDocDesc] = useState("");
    const [username, setUserName] = useState("");

    const [usernameBTN, setUserNameBTN] = useState("");
    const [passwordBTN, setPasswordBTN] = useState("");

    const [errorMessage, setErrorMessage] = useState(false);
    const [validationError, setValidationError] = useState(false);

    const [password, setPassword] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [templateSelection, setTempSelec] = useState(false);
    const [template, setTemplate] = useState([]);
    const [allForms, setAllForms] = useState([]);
    const [ind, setInd] = useState(0);
    const [selectedForms, setSelectedForms] = useState([]);

    const [fileNameHandleAddField, setForHandleAddField] = useState('');

    const [selectedTemplateId, setSelectedTemplateID] = useState("");
    const [selectedForm, setSelectedForm] = useState("");
    const [formulaireValues, setFormulaireValues] = useState([]);

    const [openWaiting, setOpenWaiting] = useState(false);

    const onChangeFile = (e) => {
      setForHandleAddField([e.target.files[0]]);
      setFileName(prevFileNames => [...prevFileNames, e.target.files[0]]);
  };
    const driverObj = driver({
      
     
      allowClose: true,
      
      
      steps: [
        {  element: '.block1', popover: { title: 'Informations', description: 'Remplissez les informations sur votre client.', 
        side: "left", align: 'start' }},
        { element: '.ligne2bis', popover: { title: 'Générez un compte client', description: 'Générer un nom d\'utilisateur et un mot de passe pour votre client. <br/> <br/> Ce dernier utilisera ces identifiants pour se connecter sur son espace client.', 
        side: "left", align: 'start'}},
        { element: '#individualDoc', popover: { title: 'Demander des documents', description: 'Vous pouvez ajouter des documents individuellement en cliquant ici.', 
        side: "left", align: 'start'}},
        { element: '#selectTemplate', popover: { title: 'Demander des documents', description: 'Ou sélectionnez un template si vous avez l\'habitude de demander les mêmes documents. <br/><br/>  Rien ne vous empêche de demander des documents individuellement en plus également.', 
        side: "left", align: 'start'}},
        { element: '.ligne3', popover: { title: 'Demander des documents', description: 'Vous pouvez également ajouter des documents de références pour votre client (ex: formulaire à remplir, lettre attestation bancaire etc.) ici ou dans votre template. <br/> <br/> Ces documents seront alors visibles dans son espace client.', 
        side: "left", align: 'start'}},
        { element: '.ligne3bis', popover: { title: 'Définir une date limite', description: 'Définissez une date limite à laquelle vous souhaitez recevoir tous vos documents. <br/><br/>  Passé cette date, votre client recevra un email personnalisé avec la liste des documents qu\'il n\'a pas encore soumis. Vous serez également mis en copie sur ce mail. ', 
        side: "left", align: 'start'}},
        
        
        
      ],

      onCloseClick: () => {
        //setOpen(true);
       
        if ( window.confirm("are you sure ?")) {
         driverObj.destroy();
       }
         
         //driverObj.destroy();
       
     },

     

     onDestroyed: async () => {
       try {
         const response = await Axios({
           method: "PUT",
           withCredentials: true,
           url: `${backEndUrl}/update/showGuide/${data.id}`,
           data: {
             showGuide: false
           }
         });
         
         // Optionally, proceed with further actions after the update
       } catch (error) {
         console.error('Failed to update guide status:', error);
         // Handle errors, maybe inform the user
       }
     } 

  
    });


    useEffect(() => {

      if (data && data.showGuide) {
        driverObj.drive();
      }
    }, [data]);

  
    const [openConfirmation, setOpenConfirmation] = React.useState(false);

    const [openBackdrop, setOpenBackDop] = React.useState(false);
    const handleCloseBackDrop = () => {
      setOpenBackDop(false);
    };
    const handleOpenBackDrop = () => {
      setOpenBackDop(true);
    };


    const handleClickOpen = (e) => {
      e.preventDefault();

      if (!usernameBTN || !passwordBTN) {
      

        setValidationError(true)
   
           return; // Exit the function
         }
 
  /*         if (data.clients.length > 3 && data.subscription === 'free') {
  
           setClientLimit(true)
         
         return;
         }  */
 
        /*  if (newDocs.length > 20 && data.subscription === 'free') {
           setDocLimit(true)
           return;
         } */
         setValidationError(false)
      setOpenConfirmation(true);
    };

    const emailInput = (e) => { 
      e.preventDefault();
      let updatedEmail = ''

      if (e && e.target && e.target.value) {
        updatedEmail = e.target.value; // Update the variable if a value is present
      }

      setNewEmail(updatedEmail)


      
      if (updatedEmail === data.email) {
   
        setValidationEmailError(true)
      } else {
        setValidationEmailError(false)
      }
    }

  
    const handleCloseConfirmation = () => {
      setOpenConfirmation(false);
    };


    // for green pop up

    const [open, setOpen] = React.useState(false);
    
    const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClick = () => {
      setOpenBackDop(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    // end for green pop up

    const handleAddForm = (e) => {
    
      e.preventDefault();

      const selectedFormName = selectedForm;
      const selectedFormInFunction = allForms.find((option) => option.name === selectedForm);
     // const selectedFormLink = selectedForm;
 

      const val = [...selectedForms];

     
        val.push({
          name: selectedFormInFunction.name,
          link: selectedFormInFunction.link,
          status:"uncomplete"
          }) ;

    setSelectedForms(val)
 
    } 





    useEffect(() => {
      Axios ({
         method: "GET",
        withCredentials: true,
          url: `${backEndUrl}/auth/consultantLog`,
   
       
        }).then((res) => {
         setData(res.data);       
         setTemplate(res.data.docTemplates)
         setAllForms(res.data.allForms)
        });
   }, []) 


   



   const successfulSubmit = () => {
    setOpenWaiting(true)
  }
  
  const newClient = async (e) => {
    e.preventDefault();

    handleOpenBackDrop();
  
    if (!usernameBTN || !passwordBTN) {
      setValidationError(true);
      return; // Exit the function
    }
  
    setValidationError(false);
  
    const formData = new FormData();
    formData.append('client_firstName', newName);
    formData.append('client_lastName', newLastName);
    formData.append('program', program);
    formData.append('consultantEmail', data.email);
    formData.append('client_Username', usernameBTN);
    formData.append('client_Password', passwordBTN);
    formData.append('client_Email', newEmail);
    formData.append('subscription', data.subscription);
    formData.append('dateDeadline', newDate);
    formData.append('docNumber', numberOfDocs);
    formData.append('noDeadline', isChecked);
    formData.append('forToComplete', selectedForms);
    formData.append('numberOfClients', data.clients.length + 1);
    formData.append('templateId', selectedTemplateId);
  
     
    // Append documents if any
    newDocs.forEach((doc, index) => {
      
      formData.append(`documents[${index}][name]`, doc.docName);
      formData.append(`documents[${index}][description]`, doc.docDescription);
      formData.append(`documents[${index}][doc_upload]`,  doc.doc_upload_consultantSide ||  'none' );
      // formData.append('docBob', doc.doc_upload_consultantSide || "");
      //formData.append('docBob', doc.doc_upload_consultantSide );
      
    });
  
    // Append the selected file if any
    fileName.forEach((file) => {
      if (file) {
        
          formData.append('doc_upload', file);
      }
  });

    for (let [key, value] of formData.entries()) {
      
    }
    // url: `${backEndUrl}/update/` + data.id,

    try {
      const response = await Axios.put(`${backEndUrl}/update/${data.id}`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
    })
    
   
    .then((response) => {

    
      setOpen(true);
      handleCloseBackDrop();
      handleCloseConfirmation();
      setTimeout(() => {
        window.location.reload();
      }, 3000); 

       
      
         
      }
    );
    } catch (error) {
      console.error('Failed to create client:', error);
    }
  };


      // 
const [formValues, setFormValues] = useState([]);
const [toggle, setToggle] = useState(false);

const inputRef = useRef();
const inputRefDes = useRef();
const selectRef = useRef();



   const handleChange = (e, index) => {
    const values = [...formValues];
    values[index].value = e.target.value;
    setFormValues(values);
  };    

  const handleAddField = (e) => {
    e.preventDefault();
    const values = [...formValues];
    const newFileIndex = fileName.length - 1
    
    values.push({
      docName: newDocName  ,
      docDescription: newDocDes ,
      //Honestly not sure which one works here
      doc_upload:fileName,
      doc_upload_consultantSide: fileNameHandleAddField
      // value: "",
    });
    
    setFormValues(values);
    setToggle(false);
    setNumberDoc(numberOfDocs + 1);
    setNewDocs(values)
   
   
  };


  // -----------

  const handleAddFieldTemplate = (e) => {
    e.preventDefault();

        const selectedTemplateName = e.target.value;
        const selectedTemplate = template.find((option) => option.name === selectedTemplateName);
        const selectedDocuments = selectedTemplate.documents;
     


setSelectedTemplateID(selectedTemplate._id)

    const values = [...formValues];

    selectedDocuments.forEach((document) => {
      values.push({
        docName: document.name,
        docDescription: document.description,
        doc_upload_consultantSide: document.doc_upload_consultantSide
      });
    });

/*     values.push({
      docName: newDocName || "label",
      docDescription: newDocDes || "text",
      // value: "",
    }); */
    setFormValues(values);
    setToggle(false);
    setNumberDoc(values.length);
    setNewDocs(values)

  };

  // ------------

 /*  const handleDeleteField = (e, index) => {
    const values = [...formValues];
    
    values.splice(index, 1);
    setFormValues(values);
  }; */



  const handleDeleteField = (e, index) => {
    e.preventDefault();
    setInd(index)
    const values = [...formValues];
    values.splice(index, 1);
    setFormValues(values);
    setNumberDoc(numberOfDocs - 1)
  };

  const addBtnClick = (e) => {
    setNewDocDesc('')
    setNewDocName('')
    setForHandleAddField('')
    e.preventDefault();
    setToggle(true);
  };

  const generateUsername = async () => {

    if (newLastName === 'aa') {
      setErrorMessage(true);
      return
    }

    if (newLastName.length < 2) {
      throw new Error('Last name must have at least two characters');
    }

    setErrorMessage(false);
    const firstTwoLetters = newLastName.slice(0, 2);

    const randomNumbers = Math.floor(Math.random() * 900) + 100;
  
   
    const username = `${firstTwoLetters}${randomNumbers}`;
  

        setUserNameBTN(username);
     
  };
  const generatePassword = async () => {

    const length = 6;
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let password = "";

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    password += charset.charAt(randomIndex);
  }

setPasswordBTN(password)
     
  };

  if (!data) {
    return (
    <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  )
  }

    return (

<div>

        <div className="home">
        <Sidebar
           storageUsed={data.storageUsed}
        />
   

    <div className="homeContainer">
    <Navebar
        userID={data.id}
            email={data.email}
    />


   
  
  
    <form   onSubmit={handleClickOpen}>
    <h2 style={{marginTop:'5%', marginLeft:'20%'}}>Création d'un client </h2>
    <div className="tonForm">
  <div className="block1">

    <div className="ligne1">

  <div className="nomPrenom">


  <label className="stp" >Prénom</label>

<TextField   required size="small" type="text" placeholder="Enter Name" onChange={(e) => setNewFirstName(e.target.value)}
     style={{ marginLeft: '15px', boxSizing: 'border-box' }}
/> 



 </div>

 <div className="courriel">

 <label>Nom</label>
<TextField required size="small" type="text" placeholder="Enter Name" onChange={(e) => setNewLastName(e.target.value)}
     style={{ marginLeft: '50px', boxSizing: 'border-box' }}
/> 



</div>


</div>




<div>

      <Backdrop
        sx={{ color: '#fff', zIndex: 1301, position: 'fixed' }}
        open={openBackdrop}
  
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>


<div className="ligne2">



<div className="nomFamille">

<label> Email</label>
<TextField type="email" size="small" placeholder="Enter Email"  onChange={(e) => emailInput(e)}
     style={{ marginLeft: '30px', boxSizing: 'border-box' }}
/> 
{emailError ? <p style={{ color: 'red' }}>Attention l'e-mail du client ne peut pas être le même que le votre.</p> : null}



</div>

</div>

<div className="prog">
{/* 
<label> Program </label>
<TextField
          id="outlined-select-currency"
          select
          label="Select"
          defaultValue="EUR"
          helperText="Please select your client's program"
          size="small"
          style={{ marginLeft: '30px', boxSizing: 'border-box' }}
          onChange={(e) => setProgram(e.target.value)}
        >
          {programs.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
 */}


</div>

</div>

<div className="ligne2bis">
    
    <div className="nomFamille">
<label>Nom d'Utilisateur</label>
<Button style={{'marginLeft' : '25px'}}variant='outlined' size='small' onClick={generateUsername}>Générer</Button>
{errorMessage ? <p style={{ color: 'red' }}>Veuillez écrire le nom de famille d'abord</p> : null}
{usernameBTN ? <p style={{'marginTop' : '15px'}} >Nom d'Utilisateur : {usernameBTN}</p> : null}
{/* <TextField size="small" type="text" placeholder="Enter Username" onChange={(e) => setUserName(e.target.value)}
     style={{ marginLeft: '28px', boxSizing: 'border-box' }}
/>  */}
</div>

<div className="password">
<label>Mot de passe</label>
<Button style={{'marginLeft' : '25px'}}variant='outlined' size='small' onClick={generatePassword}>Générer</Button>
{passwordBTN ? <p style={{'marginTop' : '15px'}}>Mot de passe : {passwordBTN}</p> : null}
{/* <TextField size="small" type="text" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)}
     style={{ marginLeft: '28px', boxSizing: 'border-box' }}
/>  */}
</div>



    </div>

    <h3 style={{marginTop:'3%'}}>Documents</h3>

<div className="ligne3">


<div id="selectTemplate">
<label className="smallMarginTop">Séléctionner un template</label>
<div>
<TextField
          id="outlined-select-currency"
          select
          label="Select"
          defaultValue="EUR"
          helperText="Vous pouvez en créer un dans le menu 'Template'"
          size="small"
          style={{ marginTop: '20px', boxSizing: 'border-box' }}
          onChange={handleAddFieldTemplate}

        >
          {template.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        </div>
  
</div>






{/* selection for the all forms */}
{

 }
 
    
    {!toggle ? (
      <div className="center" id="individualDoc">
   
      <label>et/ou demander des documents individuellement</label> 
        <Button startIcon={<AddCircleIcon />} variant="contained" className="add-new-doc" onClick={addBtnClick} size='medium' style={{marginLeft:'30px'}}>
         Ajouter
        </Button>
      </div>
    ) : (
      <div className="enfer">
      <label>Ajouter un document </label> 
         <TextField size="small" type="text" placeholder="nom du document" className="inputDocName" onChange={(e) => setNewDocName(e.target.value)}  style={{ marginTop: '30px'}} />
        <TextField id="outlined-multiline-static" multiline rows={4} className="inputDocDes" type="text" onChange={(e) => setNewDocDesc(e.target.value)} placeholder="Description du document"  cols={5} ref={inputRefDes} 
        style={{ marginTop: '30px'}}
         /> 

      <div style={{ marginTop:'6%'}}>
 
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">(facultatif)</Typography>
            <br/>
        <p>Vous pouvez également joindre un document de référence à votre client (ex: un formulaire à remplir, un modèle d'attestation bancaire à suivre etc.) </p>
      
        <p>Ce document restera visible dans son espace client.</p>
          </React.Fragment>
        }
      >
            <HelpOutlineIcon fontSize="medium" color="primary"/>
      </HtmlTooltip>
      <label style={{ marginLeft: '10px' }} htmlFor="file">Téléverser votre fichier</label>
      <input  style={{ marginLeft: '10px' }} type="file" fileName="doc_upload"  onChange={onChangeFile} /> 
        </div>
        {/* <select ref={selectRef}>
          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="email">Email</option>
          <option value="password">Password</option>
        </select>  */}
        <Button variant="contained" className="submit-doc" onClick={handleAddField} style={{marginTop:'30px'}}>
          Ajouter
        </Button>
       
      </div>
     
    )}

    </div>






<div className="realHell">

<h4>Liste des documents demandés</h4>
{formValues.map((obj, index) => (
      <Input
        key={index}
        objValue={obj}
        onChange={handleChange}
        index={index}
        deleteField={handleDeleteField}
      />
    ))}

</div>

<div className="smallMarginTop">

    

</div>

<div className="ligne3bis smallMarginTop">
<label>Définir une date limite</label>
<TextField required size="small" type="date" placeholder="Enter Deadline" onChange={(e) => setNewDate(e.target.value)}
      style={{ marginLeft: '30px', boxSizing: 'border-box' }} 
/>
</div>



 <div className="ligne4">
 <React.Fragment>
  
    <Button startIcon={<AccountCircleIcon />}variant="contained" type="submit" className="sub-btn">
      Créer client
    </Button>
    
    <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Créez ce client ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">

          {
          clientLimit ?
      <p style={{color:'red'}}>

      Vous avez atteint la limite d'emails pour la version gratuite pour ce mois-ci. Votre client a bien été créé, mais aucun email n'a été envoyé. <br/><br/>
      Pour bénéficier de plus de ressources et pouvoir envoyer plus d'emails à vos clients, nous vous invitons à mettre à jour votre abonnement.
      </p>
   
       : null }
            Voici l'email que vous allez envoyer à votre client :
            <br />
            <br />
            <span style={{ fontSize: 'smaller', fontStyle: 'italic' }} >Note : en cas de réponse à cet email, celui-ci sera automatiquement redirigé à votre adresse.</span>
            <br />
            <br />
            Bonjour {newName} {newLastName},
            <br />
            <br />
            Voici la liste des documents dont nous allons avoir besoin pour traiter votre dossier :
            <br />
            <br />

            
       
            {newDocs ? newDocs.map((doc, index) => (
  <li key={index}>
    {doc.docName} : {doc.docDescription}
   <br/>
   <br/>
  </li>
)) : null}
     

            <br />
    
            Merci de bien vouloir téléverser ces documents sur votre espace client à l'adresse suivante : https://docup-visa.com/login
            <br />
            <br />
            Voici vos identifiants de connexion :
            <br />
            <br />
            Utilisateur : {usernameBTN}
            <br />
            Mot de passe : {passwordBTN}
            <br />
       
            
            <br />
            Merci de téléverser ces documents avant le {newDate}
            <br />
            <br />
            Cordialement,

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleCloseConfirmation}>Non</Button>
          <Button variant='contained' onClick={newClient}>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>





    {
          validationError ?
      <p style={{color:'red'}}>

         Erreur : merci de d'abord générer un 'username' et 'password'
      </p>
   
       : null }
    </div>

    

    {
          DocLimit ?
      <p>

         Erreur : Pour la version demo, vous ne pouvez pas ajouter plus de 20 documents.
      </p>
   
       : null }
    
    
    <Stack spacing={2} sx={{ width: '100%' }}>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Client crée avec succès !
        </Alert>
   
      </Snackbar>
      </Stack>







</div>



</form>



    
    </div>
    </div>
    

    
</div>
    )
    }

