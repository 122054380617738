import { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { useParams, useLocation} from 'react-router-dom';
import { Navigate, useNavigate } from 'react-router-dom';
import Documents from '../../component/document/Document'
import Clients from '../../component/client/Client'
import Input from "../../component/input/Input";
import Notifications from "../../component/notification/Notification";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import "./Homepage.scss"

import Datatable from "../../component/datatable/Datatable";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';









export default function HomepageCons() {

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState(null);


  

    const [open, setOpen] = React.useState(false);

    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const handleCloseBackdrop = () => {
      setOpenBackdrop(false);
    };
    const handleOpenBackdrop = () => {
      setOpenBackdrop(true);
    };

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  

    const driverObj = driver({
    

   

      onPrevClick:() => {
        
        handleClickOpen();
        // Implement your own functionality here
      
      },
      
      onComplete:() => {
        
     },
      
      
        steps: [

          { popover: { title: 'Bienvenue sur DocUp !', description: 'Voci un petit guide pour vous aider à vous familiariser avec la plateforme. Vous allez voir c\'est très simple !', 
          side: "left", align: 'start' ,   }},
          { element: '#new-client', popover: { title: 'Créez un client', description: 'Pour commencer, cliquez sur ce bouton pour créer un nouveau client.', 
          side: "left", align: 'start',    showButtons: []  }},
         
         
        ],

        onDestroyStarted: () => {
           //setOpen(true);
          
           if ( window.confirm("are you sure ?")) {
            driverObj.destroy();
          }
            
          
          
        },

        onDestroyed: async () => {
          try {
            const response = await Axios({
              method: "PUT",
              withCredentials: true,
              url: `${backEndUrl}/update/showGuide/${data.id}`,
              data: {
                showGuide: false
              }
            });
            
            // Optionally, proceed with further actions after the update
          } catch (error) {
            console.error('Failed to update guide status:', error);
            // Handle errors, maybe inform the user
          }
        } 
      });
/* 
      useEffect(() => {
        // Start the tour
        driverObj.drive();
    
        // Cleanup function to stop the tour when the component unmounts or route changes
        return () => driverObj.destroy();
      }, [location]); // Depend on location to react to route changes
     */

      // driverObj.drive();
 
useEffect(() => {
    Axios ({
       method: "GET",
      withCredentials: true,
        url: `${backEndUrl}/auth/consultantLog`,
 
     
      }).then((res) => {
       setData(res.data);       
      });
 }, []) 

 

 useEffect(() => {

  if (data && data.showGuide) {
    driverObj.drive();
  }
}, [data]);

const handleUpgrade = async () => {

    try {
      const response = await Axios({
        method: "POST",
        data: {
          consultantEmail: data.email,
          consultantID: data.id
        },
        withCredentials: true,
        url: `${backEndUrl}/update/backend`,
      });

     if (response.status !== 200) {
        throw new Error('Network response was not ok');
      } 

      const body = response.data;
      // Handle the response data
      window.location.href = body.url;
    } catch (error) {
      console.error('Error:', error);
    }
  
};
 

function createNotif(notif) {
    return <Notifications 
        message={notif.message}
        rid={notif._id}
        date={notif.date}
        closed={notif.closed}
        type={notif.type}
    />
}





if (data === undefined || !data) {

    return (
<div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>      
      )

}


else {




    return(

    
   
    
      


        <div className="home">
       
            <Sidebar
              rid={data.id}
              storageUsed={data.storageUsed}
            />
       

        <div className="homeContainer">
        <Navebar
           userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
        />
        
       
      <div className="listContainer">

      
      <div>
      <h1 id="guide">Bonjour {data.name} </h1>

      <div>
        
      </div>

      {data.notifications.length === 0 || !data.notifications.some(notification => notification.closed === false) ? <p style={{marginTop:'2%'}}>Aucune nouvelle notifications </p> :       
      <div>
       <p style={{marginTop:'2%'}}>Notifications</p>
       <div className="notification-container">

          { data.notifications.map(createNotif) } 
       </div>
          </div> }
    
        </div>
   


   
        <Datatable 
              clients={data.clients}
              rid={data.id}
              consultantStorage={data.storageUsed}
              getRowId={(row) => row.data.clients._id}
              getRowClient={(row) => row.data.clients}

           
        />
  
        </div>
        


        <div> 
     

      
      

      
     



   

        </div>

 </div>
 <React.Fragment>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
  
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Voulez vous vraiment quitter le guide ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button onClick={handleClose} autoFocus>
            Oui
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
 </div>
 
    ) 
}
  
}