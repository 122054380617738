// Purpose: To test the login page design
//
import "./LoginDesign.scss"
import { useState } from "react";
import Axios from "axios";
import { Navigate, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';



export default function LogIn () {


  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [loginUsername, setLoginUsername] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();




  const login = (event) => { 
    
    setLoading(true);
    event.preventDefault(); 
 
    Axios({
      method: "POST",
      data: {
        username: loginUsername,
        password: loginPassword,
      },
      withCredentials: true,
      url: `${backEndUrl}/auth/consultantLog`,
     
      
    }).then((res) => {
      setLoading(false);
    if (res.data !== 'No User Exists') {

          navigate('/homepage-consultant/' + res.data._id);
      } else {
    
          setErrorMessage(true);
      }
  }).catch((err) => {
    setLoading(false);
    if (err.response && err.response.status === 403 && err.response.data.message === 'Upgrade Required') {
      
      navigate(`/upgrade/${err.response.data.userEmail}`);
  } 
  
  if  (err.response && err.response.status === 429) {
     // Log the rate limit message
    alert(err.response.data.message);
  }
  else {
      console.error(err);
      setErrorMessage(true);
  }
  });
 
  };


    return (
        <section>
          <div className="box">
            <div className="square" style={{ '--i': 0 }}></div>
            <div className="square" style={{ '--i': 1 }}></div>
            <div className="square" style={{ '--i': 2 }}></div>
            <div className="square" style={{ '--i': 3 }}></div>
            <div className="square" style={{ '--i': 4 }}></div>
            <div className="square" style={{ '--i': 5 }}></div>
    
            <div className="container">
              <div className="form">
                <h2>Connexion - DocUp</h2>
                <form >
                  <div className="inputBx">
                  
                    <input type="text" required="required"
                     onChange={(e) => setLoginUsername(e.target.value)}
                     />
                   <span>Username</span>
                    <i className="fas fa-user-circle"></i>
                  </div>
                  <div className="inputBx password">
                    <input
                      id="password-input"
                      type="password"
                      name="password"
                      required="required"
                      onChange={(e) => setLoginPassword(e.target.value)}
                    />
                    <span>Mot de passe</span>
                    {/* <a href="#" className="password-control" onClick={showHidePassword}></a> */}
                    <i className="fas fa-key"></i>
                  </div>
             {/*      <label className="remember">
                    <input type="checkbox" />
                    Remember
                  </label> */}
                  <div className="inputBx">
                    <input type="submit" value="Log in" onClick={login}  />
                    {loading ? <CircularProgress  size={20} style={{marginLeft: '30%', color:'white'}}  /> : null}
                  </div>
                </form>
                {errorMessage && <p style={{color:'red', marginBottom:'15px'}}>Invalid username or password</p>}
                <p>
                  Mot de passe oublié ? <a href="/passwordForgotten">Cliquer ici</a>
                </p> 
                <p>
                  Pas de compte ? <a href="/register">Créer un ici !</a>
                </p>
              </div>
            </div>
          </div>
        </section>
      );

}

