import { useState, useEffect } from "react";
import Axios from "axios";
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import TemplateTable from "../../component/templatetable/TemplateTable";
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

export default function TemplateList() {

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    const [data, setData] = useState();

const id = window.location.pathname.split("/")[2]


    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

    
     

     if (data === undefined) {

        return (
            <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  
        )
    }
    
    else {

    return (
        <div>
             <div className="home">
        <Sidebar
             storageUsed={data.storageUsed}
        />
  

    <div className="homeContainer">
    <Navebar
          userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
    />


    <h2 style={{marginLeft : '3%', marginTop : '3%', marginBottom:'3%'}}>Liste de vos template</h2>

    <div>
        <Button variant="outlined" style={{marginLeft : '3%'}} href={"/docTemplate/" + id} >Ajouter un template</Button>
    </div>

     <TemplateTable
              clients={data.docTemplates}
              rid={data.id}
              getRowId={(row) => row.data.docTemplates._id}
   
        /> 
        </div>
        
        </div>
      
        </div>
    )
}
}

