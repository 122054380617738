import React, { useState, useEffect } from 'react';
import "./Setting.scss";

import axios from "axios";



import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import Button from '@mui/material/Button';

const SettingsPage = () => {

    const [data, setData] = useState(null);
    const [cancellSucessfull, setCancellSucessfull] = useState(false);

    const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

    useEffect(() => {
        axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 

     

    const handleCancelSubscription = async () => {
        try {
            const response = await axios({
              method: "POST",
              withCredentials: true,
              url: `${backEndUrl}/users/cancel-subscription`,
              data: {
                subscriptionID: data.subscriptionID,
                consultantEmail: data.email,
              }
            });

            if (response.status === 200) {
              
              setCancellSucessfull(true);
              // redirect to /
            
            }

            } catch (error) {
            console.error('Failed to update guide status:', error);

            }
            }


if (!data) {
  return <div>Loading...</div>
}


    return(

    
   
    
      


        <div className="home">
       
            <Sidebar
           storageUsed={data.storageUsed}
            />
       

        <div className="homeContainer">
        <Navebar
            userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
        />
        
       
      <div className="settings">

      
      <div>

        <h1 id="guide">Réglages</h1>

        <div className='abonnement'>
            <h2>Abonnement </h2>
            <div className='annuler'>
                <h3>Annuler votre abonnement</h3>
                 {data.subscription !== 'free' ? <Button onClick={handleCancelSubscription} variant='contained' size='small' color='error' style={{marginLeft:'30px'}}>Annuler</Button> :
                 <Button onClick={handleCancelSubscription} disabled variant='contained' size='small' color='error' style={{marginLeft:'30px'}}>Annuler</Button>
                 }
        
                {cancellSucessfull && <p style={{color:'green', marginLeft:'2%'}}>Abonnement annulé avec succès</p>}
               
            </div>
        </div>
    
        </div>
   


   
  
  
        </div>
        


        <div> 
     

      
      

      
     



   

        </div>

 </div>

 </div>
 
    ) 
};

export default SettingsPage;
