import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";

export default function Document(props) {


  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS

  const [fileName, setFileName] = useState("");
  const [link, setLink] = useState("");
  const [data, setData] = useState();

  const onChangeFile = (e) => {
      setFileName(e.target.files[0]);
    };

  const changeonclick = (e) => {
    
 
      e.preventDefault();
    
      const formData = new FormData();
   
    

      formData.append("doc_upload", fileName);
     
      var docLength = data.documents.length;
  
      // we append whatevr var we want to send that is stock in the state "data"
 
      // ici tu envois tous les id de tous les docs du client pour qu'ensuite il fase le trie dans le users route
    /*   for (var i = 0; i < docLength; i++) {
        formData.append("doc_id" + i , data.documents[i]._id);
      } */

      
        formData.append("doc_id" , props.rid);
        formData.append("client_name", props.clientName);
      

  
      formData.append("_id", data._id);
      

      Axios.put(`${backEndUrl}/users/` + data._id , formData, {
          headers: {
              "Content-Type": "multipart/form-data",
          },
      }).then((res) => {
        window.location.reload();
        setLink(res.data.path);
       
      }
      );
  }

  useEffect(() => {
    Axios ({
       method: "GET",
      withCredentials: true,
        url: `${backEndUrl}/auth`,
 
     
      }).then((res) => {
       setData(res.data);

       
      });
 }, []) 
   

    return (
      <div className="header">
       <form onSubmit={changeonclick} encType="multipart/form-data">
       <p>{props.name}</p>
       <p>{props.description}</p>
       <p>{props.rid}</p>
       <img src={`${backEndUrl}/documents/`+ props.docUpload} />
       <label htmlFor="file">Choose article image</label>
       {/* Here I have a dynamic file name that is dynamic only based on the props.name */}
        <input type="file" fileName="doc_upload" name={props.rid} onChange={onChangeFile}/> 
        
      
       <button type="submit">Uploaaaad</button>

</form>
      </div>
    );
  }