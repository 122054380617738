// Desc: This is the page for the docTemplate
import * as React from 'react';
import './DocTemplate.scss'
import Sidebar from "../../component/sidebar/Sidebar";
import Navebar from "../../component/navbar/Navbar";
import { useEffect } from "react";
import Axios from "axios";
import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
import Input from "../../component/input/Input";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateIcon from '@mui/icons-material/Create';


//Successful alert 
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

// Backdrop for loading
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
//

const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS


export default function DocTemplate() {
    const [data, setData] = useState();
    const [templateName, setNewTempName] = useState("");
    const [formValues, setFormValues] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [numberOfDocs, setNumberDoc] = useState(0);
    const [newDocs, setNewDocs] = useState("");
    const [limit, setClientLimit] = useState(false);

    const [fileNameHandleAddField, setForHandleAddField] = useState('');

    const [inputDoc, setInputDoc] = useState("");
    const [desDoc, setDesDoc] = useState("");


    const [fileName, setFileName] = useState([]);

    const [openBackdrop, setOpenBackDop] = React.useState(false);
    const handleCloseBackDrop = () => {
      setOpenBackDop(false);
    };
    const handleOpenBackDrop = () => {
      setOpenBackDop(true);
    };


    useEffect(() => {
        Axios ({
           method: "GET",
          withCredentials: true,
            url: `${backEndUrl}/auth/consultantLog`,
     
         
          }).then((res) => {
           setData(res.data);       
          });
     }, []) 


   
  const onChangeFile = (e) => {
    setForHandleAddField([e.target.files[0]]);
  setFileName(prevFileNames => [...prevFileNames, e.target.files[0]]);
};

     // Sucessful submit alert 

     const [open, setOpen] = React.useState(false);

     const handleClick = () => {
       setOpen(true);
     };
   
     const handleClose = (event, reason) => {
       if (reason === 'clickaway') {
         return;
       }
   
       setOpen(false);
     };

  
     // end sucessful submit alert 
  
     const newTemplate = async (e) => {

      e.preventDefault();
    
      handleOpenBackDrop();

      const formData = new FormData();
      formData.append('name', templateName);
      formData.append('docNumber', numberOfDocs);

      

      newDocs.forEach((doc, index) => {
  
        formData.append(`documents[${index}][name]`, doc.docName);
        formData.append(`documents[${index}][description]`, doc.docDescription);
        formData.append(`documents[${index}][doc_upload]`,  doc.doc_upload_consultantSide ||  'none' );



      
      });

      
      fileName.forEach((file) => {
        if (file) {
          
            formData.append('doc_upload', file);
        }
    });

 for (let [key, value] of formData.entries()) {
      
    }

      formData.forEach((value, key) => {
         // This will help you see what is being appended
      });
      
      try {
        await Axios({
          method: "PUT",
          url: `${backEndUrl}/update/newTemp/` + data.id,
          data: formData,
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },

        });
    
        successfulSubmit();
    
    /*     setTimeout(() => {
          window.location.reload();
        }, 2000); */
      } catch (error) {
        console.log('error creating template : ' + error);
        alert('There was an error creating the template. Please try again.');
      } finally {
        handleCloseBackDrop();
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    }
    


    const successfulSubmit = () => {
      setOpen(true)
    }

    const handleChange = (e, index) => {
        const values = [...formValues];
        values[index].value = e.target.value;
        setFormValues(values);
      };    
    
      const handleAddField = (e) => {
         e.preventDefault();
        const values = [...formValues];
      
        values.push({
          docName: inputDoc|| "",
          docDescription: desDoc || "",
          doc_upload:fileName,
          doc_upload_consultantSide: fileNameHandleAddField,
          // value: "",
        });
        setFormValues(values);
        setToggle(false);
        setNumberDoc(numberOfDocs + 1);
        setNewDocs(values)
    
      };
    
      const handleDeleteField = (e, index) => {
        const values = [...formValues];
        values.splice(index, 1);
        setFormValues(values);
      };
    
      const addBtnClick = (e) => {
        setForHandleAddField('');
        e.preventDefault();
        setToggle(true);
      };

if (!data) {
  return (
    <div style={{marginTop:'20%', marginLeft:'50%' }}>  
      <CircularProgress color="secondary" />
      </div>  
  )
}


    return (
        <div className="home">
        <Sidebar
           storageUsed={data.storageUsed}
        />
   

    <div className="homeContainer">
    <Navebar
        userID={data.id}
            email={data.email}
            emailCount={data.emailCount}
            subscription={data.subscription}
    />
        <div>
        <h1 style={{marginLeft: '4%', marginTop: '4%'}} >Créer un nouveau template</h1>

        <div className='templateExplaination'>
          <p>Si vous demandez souvent les mêmes documents, vous pouvez utiliser cette section pour créer un modèle de documents que vous pourrez <br/> sélectionner directement à la création de votre client.</p>
          <p>Vous pouvez également joindre des documents de référence pour vos clients (ex : formulaire à remplir, modèle d'attestation bancaire à suivre, etc.) <br/> qui seront visibles  par eux directement dans leur espace client.</p>
        </div>
<form onSubmit={newTemplate}>

<div className='templateForm'>

<div>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
  
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>


<div style={{marginTop: '4%'}}>
        <label >Nom du template : </label>
<TextField  required style={{marginLeft: '4%'}} id="outlined-basic"  size="small" type="text" placeholder="Enter Name" onChange={(e) => setNewTempName(e.target.value)}/> 
 </div>



    
{!toggle ? (
  

  <div className="addNewBtnTemplate">
  <div style={{marginTop: '4%'}}>
 <label  >Document du client : </label> 


    <Button style={{marginLeft: '4%'}} variant="contained"  onClick={addBtnClick}>
     Ajouter document
    </Button>
    </div>
  </div>
) : (
  <div className="addNewBtnTemplate">
     <TextField required size="small" type="text" placeholder="Nom du document" className="inputDoc" onChange={(e) => setInputDoc(e.target.value)} />
    <TextField style={{marginLeft: '5%', width:'50%'}} id="outlined-multiline-static" multiline rows={4} className="desDoc" type="text" placeholder="Description du document"  onChange={(e) => setDesDoc(e.target.value)}  /> 
   <div style={{ marginTop: '2%' }}>
    <label  htmlFor="file">Téléverser un document de référence (facultatif): </label>
      <input  style={{ marginLeft: '10px' }} type="file" fileName="doc_upload" onChange={onChangeFile} /> 
</div>
    <Button  startIcon={<AddCircleIcon />} style={{marginTop: '4%', width:'20%'}} variant="contained" className="mala" onClick={handleAddField}>
      Ajouter document
    </Button>
   
  </div>
 
)}

{formValues.map((obj, index) => (
      <Input
        key={index}
        objValue={obj}
        onChange={handleChange}
        index={index}
        deleteField={handleDeleteField}
      />
    ))}



 <Button startIcon={<CreateIcon />} style={{marginTop: '8%', marginBottom:'15%'}} className='submitTemplate' variant="contained" type="submit" >
      Créer
    </Button>


</div>

</form>



        </div>
        {limit ? <p style={{color: 'red', marginTop: '2%'}}>Vous avez atteint la limite du nombre de template pour votre abonnement</p> : null}
        </div>
       
  
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Template créer avec succès !
        </Alert>
      </Snackbar>
   
  
        </div>

        
    )
}