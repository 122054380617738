import React from 'react';
import { Link } from 'react-router-dom';


export default function ErrorPage() {
    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>Oops! Something went wrong.</h1>
          <p>We're sorry, but an unexpected error occurred.</p>
          <Link to="/">Go back to the homepage</Link>
        </div>
      );





}
