import { useSlotProps } from "@mui/base"

import React from 'react';

const DocForEmail = (props) => {

   
    
        return (
      

       
          

          
            <div className="docForEmail">
     <div className="reasonMap">

    
               
                {props.status === "not uploaded" && props.review !== "bad"? (
                <span> <strong>{props.name}</strong>  : {props.description.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))} </span> ) : null}
    
                
                </div>

    
            </div>
           
        )
}

export default DocForEmail;