import "./Navbar.scss";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { useContext } from "react";

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import Axios from "axios";
import { useEffect, useState } from "react";





const Navbar = (props) => {

  const [open, setOpen] = React.useState(false);
  const [subscription, setSubscription] = React.useState('');
  const [isValid, setIsValid] = useState(false);

  const handleChange = (event) => {
    setSubscription(event.target.value);
    setIsValid(event.target.value !== '')
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const backEndUrl = process.env.REACT_APP_SERVER_ADDRESS
  const [data, setData] = useState(null);


  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  
  

  const askUpgrade = async () => {
    setOpen(true);
  }

  const handleUpgrade = async () => {
    console.log('1', props.userID)
    console.log('2', props.email)
    if (isValid) {
      try {
        const response = await Axios.post(`${backEndUrl}/users/create-checkout-session`, {
          consultantEmail: props.userID,
          consultantID: props.email,
          subType: subscription
        }, {
          withCredentials: true,
        
        });
    
        if (response.status !== 200) {
          throw new Error('Network response was not ok');
        }
    
        // Handle the response data
        const body = response.data;
        window.location.href = body.url;
      } catch (error) {
        console.error('Error:', error);
      } 
    }
  }

 





  return (
    <div className="navbar">
      <div className="wrapper">

   

      {props.emailCount >= 100 && props.subscription ==='free' ? 
      <div style={{}}> 
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit">Limite atteinte</Typography>
           <p>Attention, votre limite d'envoi d'emails a été atteinte. Aucun email automatique ne sera envoyé. Veuillez passer à la version pro pour débloquer les fonctionnalités d'envoi d'emails.</p>
          </React.Fragment>
        }
      >
     <div style={{ display: 'flex', alignItems: 'center' }}>
          <ErrorOutlineRoundedIcon style={{ color: 'red' }} />
          <p style={{color: 'red', margin: '0 0 0 8px'}}>Limite d'envoi d'email atteinte</p>
        </div>
</HtmlTooltip>

 
   
      </div> : null}
      
     
     { props.subscription=== 'free' ? <p  style={{marginRight:'3%', marginTop:'2%'}}>Plan : gratuit </p> : <p style={{marginLeft:'87%', marginTop:'2%'}}>Plan : business</p>}  
      
         
      
        </div>

        
        <div className="items">
        
          <div className="item">
       
          <Button onClick={askUpgrade} variant="contained" color="success" size="small" style={{marginRight:'50px'}}startIcon={<ArrowCircleUpIcon />}  disabled={props.subscription !== 'free'}  >
        UPGRADE
      </Button> 
    

      
          </div>

       
      {/*
          <div className="item">
            <FullscreenExitOutlinedIcon className="icon" />
          </div>
          <div className="item">
            <NotificationsNoneOutlinedIcon className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <ChatBubbleOutlineOutlinedIcon className="icon" />
            <div className="counter">2</div>
          </div>
          <div className="item">
            <ListOutlinedIcon className="icon" />
          </div> */}
          <div className="item">
           {/*  <img
              src="https://www.theflagstore.ca/store/wp-content/uploads/caflag.jpg"
              alt=""
              className="avatar"
            /> */}
          </div>
        </div>

        <React.Fragment>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Mettre à niveau votre plan"}
        </DialogTitle>
        <DialogContent style={{width:'500px'}}>
          <DialogContentText id="alert-dialog-description">
           Veuillez sélectionner un plan pour continuer : 
            <div style={{marginTop:'10%', marginLeft:'5%'}}>
           <InputLabel id="demo-simple-select-label">Type d'abonnement</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={subscription}
          label="Age"
          onChange={handleChange}
          style={{width:'200px', marginTop:'4%'}}
          required
        >
        <MenuItem value='monthly'>Mensuel</MenuItem>
          <MenuItem value='yearly'>Annuel</MenuItem>
      
          </Select>


          {subscription === 'monthly' ? <p style={{marginTop:'5%'}} value='monthly'>Abonnement mensuel : 39 $ CAN/mois</p> : null}
          {subscription === 'yearly' ? <p style={{marginTop:'5%'}} value='yearly'>Abonnement annuel : 399 $ CAN/mois</p> : null}
          </div>  

          </DialogContentText>
     
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Annuler</Button>
          <Button variant='contained'onClick={handleUpgrade} autoFocus disabled={!isValid}>
            Continuer
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
      </div>
  
  );
};

export default Navbar;